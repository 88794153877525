export const SOCIAL_LINKS = {
    FACEBOOK: "https://www.facebook.com/Mallard-Bay-Outdoors-116624006387763",
    TWITTER: "https://twitter.com/mallardbay_",
    INSTAGRAM: "https://www.instagram.com/mallardbay_",
    YOUTUBE: "https://www.youtube.com/@mallardbayoutdoors8103",
    LINKEDIN: "https://www.linkedin.com/company/mallard-bay-outdoors-llc",
}

export const SUPPORT_CONTACTS = {
    PHONE: "+1 (225) 725-7543",
    EMAIL: "support@mallardbay.com",
}

export const SEARCH_PAGE_SIZE = 20

export const NUMBER_OF_RECENT_BOOKINGS = 12

export const allowedHTMLTags = ["p", "strong", "em", "u", "a", "li", "ul", "ol"]

export const QueryKeys = {
    SetupIntent: ["setup-intent"],
    WidgetSetupIntent: ["widget-setup-intent"],
    GroupPaySetupIntent: ["group-pay-setup-intent"],
    PaymentMethods: ["payment-methods"],
    GroupPayMemberPaymentMethods: ["group-pay-member-payment-methods"],
}

export const AUTH_REDIRECT_ROUTE = "/auth-redirect"

export const GUIDE_TECH_URL = "https://guidetech.mallardbay.com/"

export const RESOURCES_URL = "https://resources.mallardbay.com/"

export const PRICING_INFO_KNOWLEDGE_BASE_PATH = "how-does-add-on-pricing-work"

export const PAGE_HEADER_HEIGHT = "80px"

export const Z_INDICES = {
    FLOATING_BUTTON: 1000,
    MODAL: 2000,
    POPOVER: 3000,
}

export const VISIBILITY_STATES = {
    VISIBLE: "visible",
    HIDDEN: "hidden",
} as const

export const TEAM_MEMBER_ACT_AS_KEY =
    "https://dev.mallardbay.com/acts_as_user_id"

export const LISTING_IMAGE_PLACEHOLDER =
    "https://www.logistec.com/wp-content/uploads/2017/12/placeholder.png"

export const GROUP_PAY_MEMBER_PAYMENT_INFO_SECTION_ID =
    "group-member-join-payment-info"

export const CHECKOUT_STEPS = {
    DATES: "DATES",
    PACKAGE: "PACKAGES",
    GUESTS: "GUESTS",
    ADD_ONS: "ADD_ONS",
    LODGING: "LODGING",
    SUMMARY: "SUMMARY",
    WIDGET_PACKAGE: "WIDGET_PACKAGE",
    WIDGET_INFO: "WIDGET_INFO",
    WIDGET_PAYMENT: "WIDGET_PAYMENT",
    WIDGET_CONFIRMATION: "WIDGET_CONFIRMATION",
}

export enum CustomHeaders {
    CLIENT_NAME = "x-client-name",
    CLIENT_VERSION = "x-client-version",
    UTM_CAMPAIGN = "x-utm-campaign",
    UTM_CONTENT = "x-utm-content",
    UTM_SOURCE = "x-utm-source",
    UTM_MEDIUM = "x-utm-medium",
    UTM_TERM = "x-utm-term",
}

export const CLIENT_TYPES = {
    WEB: "web",
    WIDGET: "widget",
}

export const STRIPE_PAYMENT_METHOD_TYPES = {
    CARD: "card",
    US_BANK_ACCOUNT: "us_bank_account",
} as const

export const COMPONENT_IDS = {
    REPORT_LISTING: "report-listing",
} as const

export const QUERY_STRING_PROPS = {
    PACKAGE_ID: "package_id",
    QUOTE_ID: "quote_id",
    QUOTE_PACKAGE_ID: "quote_package_id",
    BOOKING_INVITE_ID: "booking_invite_id",
    INTERNAL_SOURCE: "is",
    INTERNAL_SOURCE_ENTITY_ID: "isei",
    INTERNAL_SOURCE_ENTITY_TYPE: "iset",
    UTM_CAMPAIGN: "utm_campaign",
    UTM_CONTENT: "utm_content",
    UTM_MEDIUM: "utm_medium",
    UTM_SOURCE: "utm_source",
    UTM_TERM: "utm_term",
}

export const UNKNOWN_ID = "UNKNOWN_ID"

export enum TypeName {
    Amenity = "Amenity",
    AddOn = "AddOn",
    QuoteAddOn = "QuoteAddOn",
    PricingPackage = "PricingPackage",
    GenericAvailabilityEntry = "GenericAvailabilityEntry",
    ListingVisitedEvent = "ListingVisitedEvent",
    BookingStartedEvent = "BookingStartedEvent",
    TeamMember = "TeamMember",
    User = "User",
    GoogleReview = "GoogleReview",
}

export const WIDGET_URL = `${process.env.GATSBY_WEB_HOST}/widget-content`
export const WIDGET_ATTRIBUTES_KEY = "watt"

export const MAX_WIDTH = "1400px"

export enum ProviderErrors {
    CurrentOutfitter = "useCurrentOutfitter must be used within a CurrentOutfitterProvider",
    MultiMediaDisplay = "useMultiMediaDisplay must be used within a MultiMediaDisplayProvider",
    CurrentListing = "useCurrentListing must be used within a CurrentListingProvider",
}
