import type { PropsWithChildren } from "react"
import React from "react"

import CollectionButton from "~components/shared/page-sections/entity-card-collection/collection-button"
import ResponsiveSlider from "~components/shared/responsive-slider/responsive-slider"
import { useTheme } from "~config/theme"

interface Props extends PropsWithChildren {
    readonly label?: string
    readonly buttonLabel?: string | null
    readonly buttonUrl?: string | null
}

export default function GenericCollectionSlider({
    label,
    buttonLabel,
    buttonUrl,
    children,
}: Props) {
    const styles = useStyles()
    const childrenAsArray = Array.isArray(children) ? children : [children]

    return (
        <>
            <ResponsiveSlider title={label} items={childrenAsArray} />
            <div className={styles.buttonContainer}>
                <CollectionButton label={buttonLabel} href={buttonUrl} />
            </div>
        </>
    )
}

function useStyles() {
    const { css, theme } = useTheme()

    return {
        buttonContainer: css({
            marginTop: theme.sizing.scale800,
            display: "flex",
            paddingLeft: theme.sizing.scale400,
            paddingRight: theme.sizing.scale400,
        }),
    }
}
