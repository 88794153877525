import type { ButtonOverrides } from "baseui/button"
import { Button, KIND, SHAPE } from "baseui/button"
import type { ComponentProps } from "react"
import React from "react"

import { TEST_IDS } from "~config/test-ids"
import { useTheme } from "~config/theme"

interface Props extends ComponentProps<typeof Button> {
    readonly label: string
    readonly fullWidth?: boolean
}

export default function CTAButton({ label, fullWidth, ...props }: Props) {
    const overrides = useOverrides(fullWidth)

    return (
        <Button
            overrides={overrides}
            kind={KIND.tertiary}
            shape={SHAPE.pill}
            {...props}
        >
            {label}
        </Button>
    )
}

function useOverrides(fullWidth?: boolean): ButtonOverrides {
    const { theme } = useTheme()

    return {
        BaseButton: {
            props: {
                "data-testid": TEST_IDS.CTA_BUTTON,
            },
            style: {
                color: theme.colors.primaryOrange,
                border: `1.5px solid ${theme.colors.primaryOrange}`,
                width: fullWidth ? "100%" : undefined,
            },
        },
    }
}
