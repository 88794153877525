import React from "react"
import { Link } from "gatsby-link"
import { AspectRatioBox, AspectRatioBoxBody } from "baseui/aspect-ratio-box"

import LoadingSkeleton from "~components/shared/loading-skeleton"
import { useTheme } from "~config/theme"
import { TEST_IDS } from "~config/test-ids"

interface Props {
    readonly imageSrc?: string
    readonly imageAlt?: string
    readonly pill: React.ReactNode
    readonly isLoading?: boolean
    readonly ratio?: number
    readonly linkTo?: string | null
}

export default function AspectImageWithPill({
    imageSrc,
    imageAlt,
    pill,
    isLoading,
    ratio = 16 / 9,
    linkTo,
}: Props) {
    const styles = useStyles()

    return (
        <AspectRatioBox
            as={linkTo ? Link : undefined}
            to={linkTo ?? ""} // It will never be "" because of the ternary operator above
            aspectRatio={ratio}
        >
            <AspectRatioBoxBody>
                {isLoading && <LoadingSkeleton />}
                {!isLoading && (
                    <>
                        <img
                            className={styles.headerImage}
                            src={imageSrc}
                            alt={imageAlt}
                            data-testid={TEST_IDS.ASPECT_IMAGE_WITH_PILL_IMAGE}
                        />
                        {pill && <div className={styles.imagePill}>{pill}</div>}
                    </>
                )}
            </AspectRatioBoxBody>
        </AspectRatioBox>
    )
}

function useStyles() {
    const { css } = useTheme()

    return {
        imagePill: css({
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
        }),
        headerImage: css({
            objectFit: "cover",
            objectPosition: "center",
            width: "100%",
            height: "100%",
        }),
    }
}
