import type { PropsWithChildren } from "react"
import React from "react"

import GenericCollectionGrid from "~components/shared/collections/generic-collection-grid"
import GenericCollectionSlider from "~components/shared/collections/generic-collection-slider"
import ImageCard from "~components/shared/image-card/image-card"
import { useMobileMediaQueries } from "~utils/use-media-queries"

export type CollectionType = (typeof TYPES)[keyof typeof TYPES]

export const TYPES = {
    GRID: "GRID",
    SLIDER: "SLIDER",
} as const

export interface Props extends PropsWithChildren {
    readonly label?: string
    readonly buttonLabel?: string | null
    readonly buttonUrl?: string | null
    readonly type?: CollectionType
    readonly isLoading?: boolean
    readonly shouldOverrideToSliderForMobile?: boolean
}

// Enough to trigger a new row for grid
const LOADING_ITEM_COUNT = 8

export default function GenericCollection({
    label,
    buttonLabel,
    buttonUrl,
    type,
    children,
    isLoading = false,
    shouldOverrideToSliderForMobile = true,
}: Props) {
    const childrenForCollection = getChildren({ children, isLoading })

    const typeToUse = useTypeToUse({ type, shouldOverrideToSliderForMobile })

    switch (typeToUse) {
        case TYPES.GRID:
            return (
                <GenericCollectionGrid
                    label={label}
                    buttonLabel={buttonLabel}
                    buttonUrl={buttonUrl}
                >
                    {childrenForCollection}
                </GenericCollectionGrid>
            )
        case TYPES.SLIDER:
        default:
            return (
                <GenericCollectionSlider
                    label={label}
                    buttonLabel={buttonLabel}
                    buttonUrl={buttonUrl}
                >
                    {childrenForCollection}
                </GenericCollectionSlider>
            )
    }
}

// TODO unit test
function useTypeToUse({
    type,
    shouldOverrideToSliderForMobile,
}: Pick<Props, "type" | "shouldOverrideToSliderForMobile">) {
    const { isMobileOnly: isMobile } = useMobileMediaQueries()

    const shouldRenderSlider = shouldOverrideToSliderForMobile && isMobile

    if (shouldRenderSlider) return TYPES.SLIDER
    return type
}

function getChildren({
    children,
    isLoading,
}: {
    children?: PropsWithChildren["children"]
    isLoading: boolean
}) {
    // When we're loading, return empty cards
    // Currently, we only use collections in the context of cards, so if we
    // ever change that we will need more flexibility for loading components
    if (isLoading) {
        const iterator = new Array(LOADING_ITEM_COUNT).fill(0)
        return iterator.map((idx) => (
            <ImageCard key={idx} isLoading title="">
                <p></p>
            </ImageCard>
        ))
    }

    return Array.isArray(children) ? children : [children]
}
